import React from 'react'
import Link from 'gatsby-link'
import { graphql, StaticQuery } from 'gatsby'

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        pages: allNodePage (sort: {fields: title}) {
          edges {
            node {
              title
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={data => (
      <header>
        <div
          style={{
            background: '#18121e',
            marginBottom: '1.45rem',
          }}
        >
          <div
            style={{
              margin: '0 auto',
              maxWidth: 960,
              padding: '1.45rem 1.0875rem',
            }}
          >
            <h1 style={{ margin: 0 }}>
              <Link
                to="/"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                {siteTitle}
              </Link>
            </h1>
        </div>
        <div
            style={{
              margin: '0 auto',
              maxWidth: 960,
            }}
        >
            <ul
              style={{
                listStyle: 'none'
              }}
            >
              <li
                style={{
                  display: 'inline',
                  padding: '5px'
                }}
              >
                <Link
                  to="/"
                  style={{
                    color: '#984b43',
                    textDecoration: 'none',
                  }}
                >Blog
                </Link>
              </li>

              {data.pages.edges.map(({ node }) => (
                <li
                  style={{
                    display: 'inline',
                    padding: '5px'
                  }}
                >
                <Link
                  to={node.fields.slug}
                  style={{
                    color: '#eac67a',
                    textDecoration: 'none',
                  }}
                >
                {node.title}
                </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </header>
    )}
  />
)

export default Header
