import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../layouts"
import Container from "../components/container"

const IndexPage = ({ data }) => (
  <Layout>
    <Container>
      <h1>Latest Posts</h1>
      <ul
        style={{
          listStyle: 'none'
        }}
      >
        {data.allArticles.edges.map(({ node }) => (
          <li>
            <Link
              to={node.fields.slug}>
              <h2>{node.title}</h2>
            </Link>
            <p>{node.created}</p>
          </li>
        ))}
      </ul>
    </Container>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allArticles: allNodeArticle(limit: 1000, sort: {fields: created, order: DESC}) {
      edges {
        node {
          title
          fields {
            slug
          }
          created(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`